html, body, #root, .App { height: 100%;}
.head-img{
    width: 150px;
}

.container-change-white{
    border: 1px solid lightseagreen;
    max-width: 500px;
    border-radius: 8px;
    background: mintcream;
    box-shadow: 3px 3px lightgrey;
}

.head-app-bar{
    background: linear-gradient(28.78deg, #1FA372 48.04%, #63B642 73.27%);
}
.signInto{
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    padding-top: 28px;
}
.main-div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.custom-button{
    box-shadow: 2px 2px yellowgreen;
    text-transform: none;
    font-size: 22px;
    padding: 6px 12px;
    width: 250px;
    height: 50px;
    border-radius: 49px;
    border: 1px solid;
    line-height: 1.5;
    background-color: #1FA372;
    border-color: #1FA372;
    color: white;
}
.custom-button:hover{
    background-color: #1c8a04;
    border-color: #3ac01c;
    box-shadow: none;
}
.custom-button:active {
    box-shadow: none;
    background-color: #197D02;
    border-color: #5ddc19;
}
.custom-button:focus{
    box-shadow: 0 0 0 0.2rem rgba(28, 173, 54, 0.5);
}

.center{
    text-align: center;
}

.term{
    font-size: 25px;
    line-height: 50px;
    font-weight: 800;
}

.para-graph-container{
    margin-top: 50px;
    padding: 25px;
}

.save-btn{
    background: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 600;
}